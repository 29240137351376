import React from "react"

export const Gift = () => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 7h-.35A3.45 3.45 0 0 0 18 5.5a3.49 3.49 0 0 0-6-2.44A3.49 3.49 0 0 0 6 5.5 3.45 3.45 0 0 0 6.35 7H6a3 3 0 0 0-3 3v2a1 1 0 0 0 1 1h1v6a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-6h1a1 1 0 0 0 1-1v-2a3 3 0 0 0-3-3Zm-7 13H8a1 1 0 0 1-1-1v-6h4v7Zm0-9H5v-1a1 1 0 0 1 1-1h5v2Zm0-4H9.5A1.5 1.5 0 1 1 11 5.5V7Zm2-1.5A1.5 1.5 0 1 1 14.5 7H13V5.5ZM17 19a1 1 0 0 1-1 1h-3v-7h4v6Zm2-8h-6V9h5a1 1 0 0 1 1 1v1Z"
      fill="#000"
    />
  </svg>
)
