import React, { ReactElement, ReactNode } from "react"
import Button from "@material-ui/core/Button"
import { CountDown } from "./CountDown"
import { Gift } from "./icons"
import close_hint from "../../../../images/icons/icon_hint_black.svg"
import * as styles from "./rightSection.module.scss"
import { ItemTooltip } from "../../../ItemTooltip"
import { handleClickHeader } from "../../../../helpers/WatcherDL/utils/handleClickHeader"

type Props = {
  withoutHintAndCountDown?: boolean
  onlyGift?: boolean
  showHint?: boolean
  hasCTA?: boolean
  countDownTime: number
  buttonText?: string
  newYear?: boolean
  hint?: ReactNode
  hintTitle?: ReactElement | string
  background?: string
  isCountdown?: boolean
}

const defaultHintTitle = (
  <>
    Халва.Десятка <br /> в подарок
  </>
)
const defaultHint =
  "Оформите карту «Халва» сейчас и получите подписку «Халва.Десятка» в подарок. Раскройте все возможности Халвы по максимуму!"

const newYearHint = `Играйте в новогоднюю игру с 04.12 до 14.01, получайте промокоды. Главный приз -автомобиль`

export const RightSection = ({
  withoutHintAndCountDown = false,
  onlyGift,
  showHint,
  hasCTA,
  countDownTime,
  buttonText,
  newYear,
  hint = defaultHint,
  hintTitle = defaultHintTitle,
  background,
  isCountdown,
}: Props) => (
  <div className={styles.container}>
    {!withoutHintAndCountDown && (
      <>
        {!onlyGift && (
          <div className={styles.halvaTenContainer}>
            {newYear ? (
              <ItemTooltip
                hint={newYearHint}
                leftPosition="-100px"
                className={{ icon: styles.hintNY }}
                showHint={showHint}
                icon={close_hint}
                hasCTA={hasCTA}
              >
                <>
                  Участвуй в<br /> розыгрыше
                </>
              </ItemTooltip>
            ) : (
              <ItemTooltip
                hint={hint}
                leftPosition="-100px"
                className={{ icon: styles.hint }}
                showHint={showHint}
                hasCTA={hasCTA}
                icon={close_hint}
              >
                {hintTitle}
              </ItemTooltip>
            )}
          </div>
        )}
        {isCountdown && (
          <div className={styles.countdown_container}>
            <Gift />
            <CountDown countDownTime={countDownTime} />
          </div>
        )}
      </>
    )}

    <Button
      style={{ background }}
      classes={{ root: styles.btn }}
      onClick={() => handleClickHeader("sticky", "header")}
    >
      {buttonText}
    </Button>
  </div>
)
